import styles from "./Projekty.module.css";

export default function Projekty() {
  return (
    <div className={styles.projecty}>
      <h1>PROJEKTY I REALIZACJE</h1>
      <h1>Strona w przebudowie - zapraszamy w krótce</h1>
      {/* <div className={styles.gallery}>
        <button>
          <img
            className={styles.photo}
            src="./../img/gallery/project1/1.jpg"
            alt="galeria 1"
          />
        </button>
        <button>
          <img
            className={styles.photo}
            src={`${process.env.PUBLIC_URL}/img/gallery/project3/1.jpg`}
            alt="galeria 2"
          />
        </button>
        <button>
          <img
            className={styles.photo}
            src={`${process.env.PUBLIC_URL}/img/gallery/project1/1.jpg`}
            alt="galeria 3"
          />
        </button>
      </div> */}
    </div>
  );
}
