import styles from "./Omnie.module.css";

export default function Omnie() {
  return (
    <main>
      <section>
        <div className={styles.omnie}>
          <h1>Eduta Garbowska</h1>
          <p>
            Jestem absolwentką Wydziału Architektury Politechniki Białostockiej.
            Ukończyłam studia na kierunku Architektura Wnętrz , otrzymując tytuł
            magistra inżyniera architekta wnętrz. Pracuję w branży od kilku lat.
            Po owocnej współpracy z biurami zewnętrznymi nadszedł czas na
            zmiany. Kierując się maksymą „Rób to co lubisz, a nie będziesz
            musiał pracować” postanowiłam wkroczyć na własną ścieżkę zawodową.
          </p>
          <p>
            Jako architekt wnętrz z pasją i zamiłowaniem, oferuję Państwu
            szeroki wachlarz usług. Począwszy od projektów koncepcyjnych i
            wizualizacji 3D, na realizacjach pod klucz skończywszy. Na co dzień
            współpracuję ze sprawdzonymi ekipami wyspecjalizowanymi w szeroko
            pojętych pracach budowlanych (murarze, glazurnicy, hydraulicy,
            elektrycy itp.) Dysponuję również sprawdzoną bazą firm meblarskich.
          </p>
          <p>
            Moja życiowa dewiza: „Nie ma rzeczy niemożliwych, są tylko trudne do
            wykonania” oraz nieustanne doskonalenie warsztatu i umiejętności
            pozwala mi sprostać Państwa oczekiwaniom. Do każdego projektu
            podchodzę indywidualnie, zwracając uwagę nie tylko na
            funkcjonalność, ale również niepowtarzalny charakter wnętrz.
          </p>
          <p>
            Te wszystkie czynniki dają mi możliwość prowadzenia projektów z
            realizacją pod klucz. Jest to wariant dla osób mieszkających w innym
            miejscu niż realizowana inwestycja, zabieganych, zapracowanych lub
            po prostu tych, ceniących święty spokój.
          </p>
          <p>
            Jeżeli chcą Państwo zweryfikować powyższe informacje, zapraszam do
            współpracy.
          </p>
        </div>
        <div>
          <p></p>
        </div>
      </section>
    </main>
  );
}
