import styles from "./Home.module.css";

import logoPng from "../img/wadera.png";

export default function Home() {
  return (
    <main className={styles.logo}>
      <img src={logoPng} alt="Wadera Studio Architektury Wnętrz"></img>
    </main>
  );
}
