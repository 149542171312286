import { NavLink } from "react-router-dom";
import { Link } from "@chakra-ui/react";

import styles from "./header.module.css";

export default function Header() {
  return (
    <div className={styles.header}>
      <Link as={NavLink} className="link" to="/">
        STRONA GŁÓWNA{" "}
      </Link>
      <Link as={NavLink} className="link" to="/Projekty">
        PROJEKTY I REALIZACJE
      </Link>
      <Link as={NavLink} className="link" to="/Oferta">
        OFERTA
      </Link>
      <Link as={NavLink} className="link" to="/O-mnie">
        O MNIE
      </Link>
    </div>
  );
}
