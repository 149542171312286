import { Route, Routes, useLocation } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Pages/Home";

import Projekty from "./Pages/Projekty";
import Oferta from "./Pages/Oferta";
import Omnie from "./Pages/Omnie";

import styles from "./index.module.css";

export default function App() {
  const location = useLocation();
  const pageName = location.pathname.replace("/", "home");
  console.log(pageName);

  return (
    <div className={styles["app-container"]}>
      <div className={styles["main-content"]}>
        <div className={styles[pageName + `-background`]}></div>

        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Projekty" element={<Projekty />} />
          <Route path="/Oferta" element={<Oferta />} />
          <Route path="/O-mnie" element={<Omnie />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}
