import { BsTelephone } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import styles from "./footer.module.css";

export default function Footer() {
  const emailAdress = "e.garbowska@waderastudio.pl";
  const phoneNumber = "733077507";
  const facebook = "https://www.facebook.com/profile.php?id=100052177363462";
  const instagram = "https://www.instagram.com/waderastudio/";

  return (
    <div className={styles.footer}>
      <div className={styles.contacts}>
        <p className={styles.name}>Edyta Garbowska</p>
        <p className={styles.telephone}>
          <BsTelephone />
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </p>
      </div>
      <div className={styles.socialMedia}>
        <div className={styles.media}>
          <FaFacebookSquare />
          <a href={facebook} target="blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className={styles.media}>
          <FaInstagramSquare />
          <a href={instagram} target="blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className={styles.media}>
          <MdEmail />
          <a href={emailAdress} target="blank" rel="noopener noreferrer">
            E-mail
          </a>
        </div>
      </div>
    </div>
  );
}
